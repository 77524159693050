<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="header justify-between">
        <div class="logo">
          <img src="./assets/img/Frame(3).png" alt="">
          <span>MoneyPlus</span>
        </div>

        <div class="nav">
          <span class="" @click="goPage('home')">หน้าแรก</span>
          <span class="" @click="goPage('about')">เกี่ยวกับเรา</span>
          <span class="active" @click="goPage('services')">บริการของเรา</span>
          <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
        </div>
        <div class="section_1_text">
          <div class="big">
            สินเชื่อด่วน สมัครง่าย บริการครบวงจร
          </div>
        </div>

      </div>
    </div>
    <div class="section_2">
      <div class="section_2left">
        <img src="./assets/img/Rectangle 66.png" alt="">
      </div>
      <div class="section_2right">
        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834.png" alt="">
          ดาวน์โหลดแอป
        </div>
        <div class="section_2right_mi">
          ขั้นตอนที่ 1: ดาวน์โหลดแอปของเรา
        </div>

        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834(1).png" alt="">
          สมัครออนไลน์
        </div>
        <div class="section_2right_mi">
          ขั้นตอนที่ 2: กรอกข้อมูลส่วนบุคคลเพื่อสมัครขอสินเชื่อ
        </div>

        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834(2).png" alt="">
          ให้สินเชื่อ
        </div>
        <div class="section_2right_mi" style="border: none;">
          ขั้นตอนที่ 3: ตรวจสอบและดำเนินการเบิกจ่ายกองทุนให้เสร็จสิ้น
        </div>
      </div>
    </div>
    <div class="section_3">
      <div class="section_3left">
        <img src="./assets/img/Group 1171274846.png" alt="">
      </div>
      <div class="section_3right">
        <div class="linebox"></div>
        <div class="righttt">
          ทีมงานมืออาชีพ
        </div>
        <div class="rightt2">
          สินเชื่อไร้กังวล บริการชั้นหนึ่ง
        </div>
        <div class="rightt3">
          เราให้ความสำคัญกับลูกค้าเป็นศูนย์กลางเสมอ ให้บริการคำปรึกษาแบบตัวต่อตัว ตอบคำถามของลูกค้า
          และมอบโซลูชั่นสินเชื่อที่เหมาะสมที่สุดแก่ลูกค้า ความไว้วางใจและความพึงพอใจของคุณคือการแสวงหาสูงสุดของเรา
        </div>
      </div>
    </div>
    <div class="section_4">
      <div class="linebox"></div>
      <div class="buildtitle">เราตอบสนองความต้องการทางการเงินของคุณ</div>

      <div class="buildtitlemm">ข้อดีหลายประการเพื่อตอบสนองความต้องการส่วนบุคคลของคุณ</div>

      <div class="buildbox">
        <div class="buildboxitem">
          <img src="./assets/img/Frame.png" alt="">
          <div class="buildboxitemtt">
            สินเชื่อมืออาชีพที่ออกแบบมาเพื่อคุณโดยเฉพาะ
          </div>
          <div class="buildboxitemmm">
            ทีมงานมืออาชีพของเราออกแบบโซลูชั่นสินเชื่อให้กับคุณ
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(1).png" alt="">
          <div class="buildboxitemtt">
            อนุมัติไว ปล่อยสินเชื่อได้ทันที
          </div>
          <div class="buildboxitemmm">
            หลังจากได้รับใบสมัครสินเชื่อของคุณแล้ว เราจะอนุมัติอย่างรวดเร็วและเบิกจ่ายทันที
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(2).png" alt="">
          <div class="buildboxitemtt">
            ยึดหลักความซื่อสัตย์ มุ่งเน้นการบริการ
          </div>
          <div class="buildboxitemmm">
            ให้บริการสินเชื่ออย่างมืออาชีพและมีประสิทธิภาพแก่คุณ
          </div>
        </div>
      </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          MoneyPlus
        </div>
        <div class="bottomtitlem">
          เรามุ่งมั่นที่จะให้บริการสินเชื่อครบวงจรแก่ลูกค้าและช่วยคุณแก้ไขปัญหาสินเชื่อ
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            391 ซอยพหลโยธิน 69/1 แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />