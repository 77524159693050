<template>
  <div class="page flex-col">
    <div class="mainbox">
      <div class="section_1 flex-col">
        <div class="header justify-between">
          <div class="logo">
            <img src="./assets/img/Frame(1).png" alt="">
            <span>MoneyPlus</span>
          </div>

          <div class="nav">
            <span class="active" @click="goPage('home')">หน้าแรก</span>
            <span class="text_3" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="text_4" @click="goPage('services')">บริการของเรา</span>
            <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              สินเชื่อด่วนเพื่อเร่งความฝันของคุณ!
            </div>
            <div class="small">
              ไม่ว่าจะเป็นความต้องการของครอบครัวหรือการวางแผนอาชีพ
              เราให้บริการโซลูชั่นสินเชื่อทางการเงินแบบมืออาชีพและสะดวกสบายแก่คุณ
              ให้เงินไม่เป็นอุปสรรคต่อความฝันของคุณอีกต่อไป!
            </div>
          </div>

        </div>
      </div>
      <div class="section_2 flex-col">
        <div class="linebox"></div>
        <div class="maintitle">
          บริการครบวงจรเพื่อมอบประสบการณ์ทางการเงินสินเชื่อที่ดีที่สุดแก่คุณ
        </div>
        <div class="mintitle">
          บริการสินเชื่อระดับมืออาชีพ โซลูชั่นสินเชื่อที่ออกแบบโดยเฉพาะสำหรับคุณ
          มุ่งมั่นที่จะมอบประสบการณ์การบริการที่สะดวก รวดเร็ว และเอาใจใส่แก่ผู้ใช้
        </div>
        <div class="stepbox justify-between">
          <div class="itemstep">
            <img src="./assets/img/Group 1171274798.png" alt="">
            <div class="stepmax">
              ความปลอดภัย
            </div>
            <div class="stepmin">
              นำเทคโนโลยีใหม่มาใช้เพื่อให้มั่นใจถึงความปลอดภัยของข้อมูลผู้ใช้
            </div>
          </div>
          <div class="itemstep">
            <img src="./assets/img/Group 1171274797.png" alt="">
            <div class="stepmax">
              สะดวก
            </div>
            <div class="stepmin">
              อนุมัติด่วน
            </div>
          </div>
          <div class="itemstep">
            <img src="./assets/img/Group 1171274797(1).png" alt="">
            <div class="stepmax">
              วงเงินสูง
            </div>
            <div class="stepmin">
              จำนวนเงินกู้สูง
            </div>
          </div>
        </div>
      </div>

      <div class="section_3 justify-between">
        <div class="mostleft">
          <div class="mostline"></div>
          <div class="mosttitle">
            สินค้ามากมาย
          </div>
          <div class="mostmin">
            ตามความต้องการของตลาดและความต้องการของลูกค้า เรามีผลิตภัณฑ์สินเชื่อที่หลากหลาย
            รวมถึงสินเชื่อเพื่อการบริโภคส่วนบุคคล สินเชื่อธุรกิจ สินเชื่อที่อยู่อาศัย ฯลฯ
            เพื่อตอบสนองความต้องการของลูกค้าที่แตกต่างกัน

          </div>
          <div class="mostmal">
            อนุมัติเร็ว
          </div>
          <div class="mostmin">
            เรานำกระบวนการอนุมัติที่มีประสิทธิภาพมาใช้และใช้เทคโนโลยีสารสนเทศขั้นสูงเพื่อให้การอนุมัติสินเชื่อรวดเร็วและแม่นยำ
            ช่วยให้ลูกค้าได้รับสินเชื่อโดยเร็วที่สุด
          </div>
        </div>
        <div class="mostright">
          <img src="./assets/img/Rectangle 65.png" alt="">
        </div>
      </div>

      <div class="section_4 justify-between">
        <div class="mostright">
          <img src="./assets/img/Rectangle 66.png" alt="">
        </div>

        <div class="mostleft">

          <div class="mostmal">
            อัตราดอกเบี้ยที่เหมาะสม
          </div>
          <div class="mostmin">
            เรากำหนดอัตราดอกเบี้ยเงินกู้ที่เหมาะสมตามเงื่อนไขตลาดและสถานะเครดิตของลูกค้าเพื่อให้แน่ใจว่าลูกค้าจะได้รับโซลูชั่นสินเชื่อที่ยุติธรรมและโปร่งใส
          </div>
          <div class="mostmal">
            ทีมงานมืออาชีพ
          </div>
          <div class="mostmin">
            เรามีทีมงานทางการเงินที่มีประสบการณ์และเป็นมืออาชีพที่มีความรู้ทางการเงินอย่างลึกซึ้งและมีประสบการณ์ในอุตสาหกรรมมากมาย
            และสามารถให้บริการสินเชื่อแบบมืออาชีพและครอบคลุมแก่ลูกค้าได้
          </div>
        </div>

      </div>
      <div class="section_5 ">
        <div class="bg"></div>
        <div class="shouldbox justify-between">

          <div class="shouldleft">
            <div class="linebox">
            </div>
            <div class="shouldmax">
              บริการทางการเงินที่จะช่วยให้การเติบโต
            </div>
            <div class="shouldmin">
              เรามุ่งมั่นที่จะให้บริการทางการเงินที่ครอบคลุมแก่ลูกค้าเพื่อช่วยให้คุณประสบความสำเร็จทั้งในด้านส่วนตัวและด้านอาชีพ และทำให้ความฝันของคุณเป็นจริง
            </div>
            <div class="shoulditem">
              <img src="./assets/img/Group 1171274797(2).png" alt="">
              ในฐานะสถาบันสินเชื่อมืออาชีพ เรานำเสนอผลิตภัณฑ์สินเชื่อที่หลากหลายเพื่อตอบสนองความต้องการที่แตกต่างกันของคุณและช่วยเหลือการเติบโตและการพัฒนาของคุณ
            </div>
            <div class="shoulditem">
              <img src="./assets/img/Group 1171274797(2).png" alt="">
              เรายึดมั่นในการบริหารจัดการที่ซื่อสัตย์เสมอ ให้บริการลูกค้าด้วยบริการคุณภาพสูงและโซลูชั่นสินเชื่อที่มีประสิทธิภาพ และเปิดประตูสู่ความมั่งคั่ง
            </div>
            <div class="shoulditem">
              <img src="./assets/img/Group 1171274797(2).png" alt="">
              เราให้บริการสินเชื่อที่สะดวกและมีประสิทธิภาพเพื่อช่วยให้คุณตระหนักถึงความต้องการทางการเงินของคุณได้อย่างรวดเร็วและทำให้ความฝันของคุณอยู่ใกล้แค่เอื้อม
            </div>
          </div>
          <div class="shouldright">
            <div class="shouldcard">
              <img src="./assets/img/Group 1171274798(1).png" alt="">
              <div class="shouldcardmax">
                วิชาเอก
              </div>
              <div class="shouldcardmin">
                สินเชื่อมืออาชีพ บริการที่นึกถึง
              </div>
            </div>
            <div class="shouldcard">
              <img src="./assets/img/Group 1171274799(1).png" alt="">
              <div class="shouldcardmax">
                เร็ว
              </div>
              <div class="shouldcardmin">
                อนุมัติเร็ว ผ่อนชำระได้ยืดหยุ่น
              </div>
            </div>
            <div class="shouldcard">
              <img src="./assets/img/Group 1171274800(1).png" alt="">
              <div class="shouldcardmax">
                โปร่งใส
              </div>
              <div class="shouldcardmin">
                อัตราดอกเบี้ยต่ำและอัตราดอกเบี้ยที่โปร่งใส
              </div>
            </div>
            <div class="shouldcard">
              <img src="./assets/img/Group 1171274801.png" alt="">
              <div class="shouldcardmax">
                ความปลอดภัย
              </div>
              <div class="shouldcardmin">
                ปลอดภัยและเชื่อถือได้ ดำเนินงานด้วยความซื่อสัตย์
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          MoneyPlus
        </div>
        <div class="bottomtitlem">
          เรามุ่งมั่นที่จะให้บริการสินเชื่อครบวงจรแก่ลูกค้าและช่วยคุณแก้ไขปัญหาสินเชื่อ
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            391 ซอยพหลโยธิน 69/1 แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />