<template>
  <div class="page flex-col">
    <div class="mainbox">
      <div class="section_1 flex-col">
        <div class="header justify-between">
          <div class="logo">
            <img src="./assets/img/Frame(4).png" alt="">
            <span>MoneyPlus</span>
          </div>

          <div class="nav">
            <span class="" @click="goPage('home')">หน้าแรก</span>
            <span class="active" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="text_4" @click="goPage('services')">บริการของเรา</span>
            <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              สินเชื่อออนไลน์ ไม่ต้องรอ ความช่วยเหลือทางการเงินที่คุณสมควรได้รับ!
            </div>
          </div>

        </div>
      </div>
      <div class="section_2 ">
        <div class="itemstep">
          <div class="num">
            0
          </div>
          <div class="stepmm">
            ค่าธรรมเนียมการจัดการ
          </div>
        </div>
        <div class="itemstep">
          <div class="num">
            24ชม
          </div>
          <div class="stepmm">
            บริการออนไลน์
          </div>
        </div>
        <div class="itemstep">
          <div class="num">
            100,000+
          </div>
          <div class="stepmm">
            ความคิดเห็นของผู้ใช้
          </div>
        </div>
      </div>
      <div class="section_3">
        <div class="linebox"></div>
        <div class="completetitle">
          การสำรวจทางวิทยาศาสตร์ สร้างความเป็นเลิศ
        </div>
        <div class="boxcom">
          <div class="boxcomitem">
            <img src="./assets/img/Rectangle 39937.png" alt="">
            <div class="boxcomitemtitle">
              บริการระดับมืออาชีพแบบตัวต่อตัว
            </div>
            <div class="boxcomitemtmmi">
              มุ่งมั่นที่จะจัดหาผลิตภัณฑ์สินเชื่อที่เหมาะสมแก่ผู้ใช้
            </div>
          </div>

          <div class="boxcomitem">
            <img src="./assets/img/Rectangle 39939.png" alt="">
            <div class="boxcomitemtitle">
              กระบวนการอนุมัติที่รวดเร็ว
            </div>
            <div class="boxcomitemtmmi">
              การสมัครออนไลน์นั้นง่ายและสะดวก
            </div>
          </div>

          <div class="boxcomitem">
            <img src="./assets/img/Rectangle 39938.png" alt="">
            <div class="boxcomitemtitle">
              การดูแลลูกค้าอย่างใกล้ชิด
            </div>
            <div class="boxcomitemtmmi">
              บริการให้คำปรึกษาโดยทีมงานมืออาชีพ
            </div>
          </div>
        </div>
      </div>
      <div class="section_4">
        <div class="engaleft">
          <img src="./assets/img/Rectangle 39961.png" alt="">
        </div>
        <div class="engaright">
          <div class="linebox"></div>
          <div class="engarighttitle">
            สินเชื่อด่วน สมัครง่าย
          </div>
          <div class="engarighttitlemm">
            ในสังคมที่กำลังพัฒนาอย่างรวดเร็วในปัจจุบัน เวลาคือเงิน เรามีขั้นตอนการขอสินเชื่อที่มีประสิทธิภาพ
            ผ่านแพลตฟอร์มออนไลน์ ลูกค้าสามารถส่งใบสมัคร อัพโหลดข้อมูลได้ในเวลาอันสั้น และรับผลสินเชื่อได้อย่างรวดเร็ว
            เพื่อให้ความฝันของคุณไม่ต้องรออีกต่อไป
          </div>
          <div class="engarighttitlemmid">
            อัตราที่โปร่งใส ไม่มีค่าธรรมเนียมแอบแฝง
          </div>
          <div class="engarighttitlemm">
            เราเข้าใจดีว่าลูกค้าของเรามีความอ่อนไหวต่ออัตราดอกเบี้ยและค่าธรรมเนียมเงินกู้
            ดังนั้นเราจึงปฏิบัติตามนโยบายที่โปร่งใสเสมอ และค่าธรรมเนียมทั้งหมดมีความชัดเจนในทันที
            คุณสามารถเลือกผลิตภัณฑ์สินเชื่อของเราได้อย่างมั่นใจและทราบภาระทางการเงินของคุณอย่างชัดเจน
          </div>
        </div>
      </div>
      <div class="section_5">
        <div class="linebox"></div>
        <div class="placetitle">
          ทางเลือกที่เชื่อถือได้ ปลอดภัย และรับประกัน
        </div>
        <div class="placemin">
          พันธมิตรทางการเงินที่ดีที่สุดของคุณ
        </div>
        <div class="placebox">
          <div class="placeboxitem">
            <div class="placeboxleft">
              <img src="./assets/img/Frame.png" alt="">
            </div>
            <div class="placeboxright">
              <div class="placeboxrighttt">
                การเบิกจ่ายเงินทุนทันเวลา
              </div>
              <div class="placeboxrightm">
                ชำระเงินด่วนหลังจากอนุมัติใบสมัคร
              </div>
            </div>

          </div>
          <div class="placeboxitem">
            <div class="placeboxleft">
              <img src="./assets/img/Frame(1).png" alt="">
            </div>
            <div class="placeboxright">
              <div class="placeboxrighttt">
                การชำระคืนที่ยืดหยุ่น
              </div>
              <div class="placeboxrightm">
                ผู้ใช้สามารถชำระคืนได้อย่างยืดหยุ่น
              </div>
            </div>

          </div>
          <div class="placeboxitem">
            <div class="placeboxleft">
              <img src="./assets/img/Frame(2).png" alt="">
            </div>
            <div class="placeboxright">
              <div class="placeboxrighttt">
                ปลอดภัยและเชื่อถือได้
              </div>
              <div class="placeboxrightm">
                การป้องกันการเข้ารหัสข้อมูลผู้ใช้
              </div>
            </div>

          </div>
          <div class="placeboxitem">
            <div class="placeboxleft">
              <img src="./assets/img/Frame(3).png" alt="">
            </div>
            <div class="placeboxright">
              <div class="placeboxrighttt">
                ความโปร่งใสของอัตราดอกเบี้ย
              </div>
              <div class="placeboxrightm">
                อัตราดอกเบี้ยผลิตภัณฑ์สินเชื่อมีความโปร่งใส
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          MoneyPlus
        </div>
        <div class="bottomtitlem">
          เรามุ่งมั่นที่จะให้บริการสินเชื่อครบวงจรแก่ลูกค้าและช่วยคุณแก้ไขปัญหาสินเชื่อ
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            391 ซอยพหลโยธิน 69/1 แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />