<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="header justify-between">
        <div class="logo">
          <img src="./assets/img/Frame(3).png" alt="">
          <span>MoneyPlus</span>
        </div>
       
          <div class="nav">
            <span class="" @click="goPage('home')">หน้าแรก</span>
            <span class="" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="" @click="goPage('services')">บริการของเรา</span>
            <span class="active" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              ติดต่อเราเพื่อการเงินที่ง่ายและการชำระเงินที่รวดเร็ว!
            </div>
          </div>
   
      </div>
    </div>
   
    <div class="section_4">
      
     

      <div class="buildbox">
        <div class="buildboxitem">
          <img src="./assets/img/Frame.png" alt="">
          <div class="buildboxitemtt">
            ติดต่อเรา
          </div>
          <div class="buildboxitemmm">
            สมัครตอนนี้และเริ่มเส้นทางสู่ความมั่งคั่ง!
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(1).png" alt="">
          <div class="buildboxitemtt">
            อีเมล์
          </div>
          <div class="buildboxitemmm">
            Suspendisse eget ipsum gravida, cursus eros quis, bibendum lectus.
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(2).png" alt="">
          <div class="buildboxitemtt">
            โทรศัพท์
          </div>
          <div class="buildboxitemmm">
            Suspendisse eget ipsum gravida, cursus eros quis, bibendum lectus.
          </div>
        </div>
      </div>
    </div>
    <div class="section_5">
      <img src="./assets/img/Rectangle 39982.png" alt="">
    </div>

    <div class="section_6">
        <div class="section_6header">
          <div class="section_6header_left">
            ติดต่อเรา
          </div>
          <div class="section_6header_right">
            เลือกบริการทางวิทยาศาสตร์และเทคนิคระดับมืออาชีพ เราเชื่อว่าด้วยความร่วมมืออย่างใกล้ชิดระหว่างคุณกับเรา เราสามารถร่วมกันบรรลุเป้าหมายของการพัฒนาทางวิทยาศาสตร์และเทคโนโลยีและสร้างอนาคตที่ดีกว่า
          </div>
        </div>
        <div class="formbox">
          <div class="name">
            <div class="label_name">
              ชื่อ
            </div>
            <div class="label_input">
              <input type="text" id="name" v-model="formName">
            </div>
          </div>
          <div class="name">
            <div class="label_name">
              ธีม
            </div>
            <div class="label_input">
              <input type="text" id="lastname" v-model="formLastName">
            </div>
          </div>
          <div class="name">
            <div class="label_name">
              จดหมาย
            </div>
            <div class="label_input">
              <input type="text" id="email" v-model="formEmail">
            </div>
          </div>
          <div class="name">
            <div class="label_name">
              โทรศัพท์
            </div>
            <div class="label_input">
              <input type="text" id="phone" v-model="formPhone">
            </div>
          </div>

          <div class="message name">
            <div class="label_name">
              ข้อมูล
            </div>
            <div class="label_input">
              <input type="text" id="message" v-model="formMessage">
            </div>
          </div>
          <div class="subbox" @click="submitdata()">
            ส่ง
          </div>
        </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          MoneyPlus
        </div>
        <div class="bottomtitlem">
          เรามุ่งมั่นที่จะให้บริการสินเชื่อครบวงจรแก่ลูกค้าและช่วยคุณแก้ไขปัญหาสินเชื่อ
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            391 ซอยพหลโยธิน 69/1 แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formName:'',
      formLastName:'',
      formEmail:'',
      formPhone:'',
      formMessage:'',
    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    },
    submitdata(){
      if(!this.formName){
        alert('กรุณาเข้า ชื่อ')
        return
      }
      if(!this.formLastName){
        alert('กรุณาเข้า ธีม')
        return
      }
      if(!this.formEmail){
        alert('กรุณาเข้า จดหมาย')
        return
      }
      if(!this.formPhone){
        alert('กรุณาเข้า โทรศัพท์')
        return
      }
      if(!this.formMessage){
        alert('กรุณาเข้า ข้อมูล')
        return
      }
      this.issub = true
      setTimeout(() => {
        this.issub = false
        this.formName = ''
        this.formLastName = ''
        this.formPhone = ''
        this.formEmail = ''
        this.formMessage = ''
        alert('Thanks for your feedback')
      }, 1500);
    },
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />